import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {handleScrollToHash} from "../../services/services";
import {tabDataLinked} from "../../constants";
import FeatureRichList from "../../components/Common/RichList/FeatureRichList";
import PhoneSwiper from "../../components/Common/PhoneSwiper";

const data = [
    {id: 0, title: 'Tại màn hình chính, chọn “Tài khoản” ở góc phải bên dưới màn hình'},
    {id: 1, title: 'Chọn “Quản lý Ví/ Thẻ”'},
    {id: 2, title: 'Chọn “Thêm liên kết ngân hàng”'},
    {id: 3, title: 'Chọn “Napas”'},
    {id: 4, title: 'Nhập thông tin in trên thẻ SHB của bạn'},
    {id: 5, title: 'Ghi nhớ mã giao dịch tại màn hình “Xác nhận thanh toán trực tuyến”'},
    {id: 6, title: 'Vào ứng dụng SHB Mobile, và chọn “Tạo mã xác thực”'},
    {id: 7, title: 'Nhập mã PIN smart OTP, sau đó nhập mã giao dịch vừa ghi nhớ ở Bước số 6'},
    {id: 8, title: '“Sao chép” mã xác thực vừa được tạo'},
    {id: 9, title: 'Quay về ứng dụng Ví GooPay, nhập mã xác thực vừa sao chép và bấm “Xác nhận”'},
    {
        id: 10, title: 'Nạp tiền 10.000đ vào Ví và liên kết thành công',
        subtitle: [
            '<i class="text-xs">Lưu ý: Hệ thống sẽ tự động chuyển 10.000đ vào Ví GooPay cho mỗi lần liên kết ngân hàng thành công.<i>',
        ]
    },
];

const dataLinked = [
    {id: 1, title: 'Thẻ đã đăng kí dịch vụ Internet Banking tại ngân hàng SHB'},
    {id: 2, title: 'Số điện thoại ví phải trùng với số điện thoại đã đăng ký tại ngân hàng SHB'},
    {id: 3, title: 'Số CMND/ CCCD đăng kí với ví phải trùng với số CMND/ CCCD đã đăng kí tại ngân hàng SHB'},
    {id: 4, title: 'Số tài khoản/ số thẻ này đang không liên kết với tài khoản ví GooPay khác'},
]
const LinkGuide = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [step, setStep] = useState(0);
    const location = useLocation();
    const {hash} = location || {};
    useEffect(() => {
        handleScrollToHash({sectionId: hash.substring(1)});
    }, [hash]);

    return (
        <div className="mx-auto w-full animate-fadeIn text-sm space-y-5 md:space-y-10 py-10 px-[30px]">
            <div className='flex justify-center items-center text-center space-x-4 text-sm md:text-[17px] font-bold'>
                <div>
                    Hướng dẫn liên kết Ví GooPay với tài khoản Ngân hàng
                </div>
                <img src='/img/SHB.svg' alt='shb'/>
            </div>
            <div
                className='flex flex-wrap md:font-normal justify-center items-center font-bold md:space-x-12'>
                <div
                    className="bg-transparent rounded-b-lg pb-4 md:pb-0"
                    role="tabpanel"
                    id={`tabpanel-${activeTab}`}
                    aria-labelledby={`tab-${activeTab}`}
                >
                    <PhoneSwiper
                      activeTab={activeTab}
                      step={step}
                      setStep={setStep}
                      tabsData={tabDataLinked}
                      setActiveTab={setActiveTab}
                    />
                </div>
                <FeatureRichList
                  data={data}
                  step={step}
                  setStep={setStep}
                  customClass='!space-y-[10px]'
                  subClass={'ml-0'}
                  linkedPage
                />
            </div>
            <div className='text-sm max-w-[800px] mx-auto'>
                <div className='space-y-2'>
                    <div className='text-left'>
                        Lưu ý: Để liên kết thành công, thẻ phải đáp ứng các điều kiện dưới đây
                    </div>
                    <div className="text-left">
                        {dataLinked.map((item, index) => (
                            <div key={index}
                                 className='flex items-start space-x-2 mb-2'>
                                <div className='w-1 h-1 min-w-1 min-h-1 rounded-full bg-black mt-2'></div>
                                <div>{item.title}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default LinkGuide;
