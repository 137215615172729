import { MENU_ITEM_ID, PATH } from '../constants';

export const handleActiveItem = (pathname) => {
  if (pathname === PATH.HOME) {
    return MENU_ITEM_ID.HOME;
  } else if (pathname.includes(PATH.SERVICES)) {
    return MENU_ITEM_ID.SERVICES;
  } else if (pathname.includes(PATH.ABOUT)) {
    return MENU_ITEM_ID.ABOUT;
  }else if (pathname.includes(PATH.HIRING)) {
    return MENU_ITEM_ID.HIRING;
  }else if (pathname.includes(PATH.CONTACT)) {
    return MENU_ITEM_ID.CONTACT;
  }
}


export const handleScrollToHash = ({sectionId}) => {
  const section = document.getElementById(sectionId);
  if (section) {
    // scrollIntoView not working?
    const y = section.getBoundingClientRect().top + window.scrollY;
    setTimeout(() => {
      window.scrollTo({top: y-100, behavior: 'smooth'});
    }, 1000)
  }
  
}

export const validateEmail = (email) => {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(String(email).toLowerCase());
};

export const isValidPhone = (phone) => {
  return /([\+84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/.test(phone);
}
