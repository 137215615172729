import {useEffect} from "react";
import {STORE} from "../../constants";
import {isMobile, isAndroid} from 'react-device-detect'
import AppDownload from "../../components/Header/AppDownload";

const Download = () => {
    useEffect(() => {
        if(isMobile) {
            if(isAndroid) {
                window.location.href = STORE.PLAY_STORE
            } else {
                window.location.href = STORE.APPS_STORE
            }
        }
    }, []);
    
    return (
      <div className='hidden md:block w-[200px] mx-auto py-12'>
          <AppDownload/>
      </div>
    )
};

export default Download;
