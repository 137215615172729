// src/components/Home.js
import React from 'react';
import CardList from '../components/CardList/CardList';
import ImageList from '../components/ImageList';
import FeatureBlock from '../components/FeatureBlock';
import FloatQRCode from '../components/FloatQRCode';
import GuildTabs from '../components/GuildTabs';
import MasterBanner from '../components/HomeBanner';
import {PATH, VARIANT} from '../constants';
import NewLink from "../components/NewLink/NewLink";
import Button from "../components/Common/Button";

const Home = () => (
  <div className="mx-auto w-full animate-fadeIn md:mt-[-90px]">
    <h1 className="hidden">GooPay - Giải pháp thanh toán toàn diện</h1>
    <MasterBanner />
    <CardList />
    <div className='w-full md:px-[128px] bg-[#FAFAFA] pt-8'>
      <div className='w-full md:gap-[100px] flex flex-col md:flex-row items-center justify-between'>
        <div className='w-[90%] md:w-1/2 text-center md:text-left'>
          <h2 className="font-bold md:text-left text-center mb-4 text-xl md:text-2xl text-gray-800">VỀ CHÚNG TÔI</h2>
          <p className="text-gray-600 mb-6 text-sm md:text-left text-center">
            GooPay ra đời với sứ mệnh cung cấp những giải pháp tài chính chuyên biệt giúp giải quyết các vấn đề thanh
            toán
            khi di chuyển, chuyển phát hàng hóa trở nên thuận tiện và nhanh chóng, cùng hệ sinh thái đa dạng và phong
            phú,
            mang tới những trải nghiệm dịch vụ hoàn hảo cho khách hàng và đối tác.
          </p>
          <NewLink to={PATH.ABOUT}>
            <Button variant={VARIANT.PRIMARY} className="text-sm">
              Xem thêm
            </Button>
          </NewLink>
        </div>
        <div className='md:w-1/2'>
          <img
            src='/img/about-us-frame.png'
            alt="InfoImageBlock"
            className='w-full h-auto'
          />
        </div>
      </div>
    </div>
    
    <ImageList/>
    <FeatureBlock/>
    <GuildTabs/>
    <FloatQRCode/>
  </div>
);

export default Home;
