import React from 'react';
import FeatureRichList from '../Common/RichList/FeatureRichList';

const data = [
    {id: 0, title: 'Tại trang “Tài khoản” bấm chọn “Xác thực tài khoản”'},
    {id: 1, title: 'Bấm “Bắt đầu” sau đó dùng camera để quét chuỗi ký tự ở mặt sau thẻ CCCD gắn chip'},
    {id: 2, title: 'Đặt thẻ CCCD lên đầu đọc thẻ NFC (mặt sau gần camera của điện thoại) sau đó bấm “Quét NFC”',
        subtitle: ['Xem thêm hướng dẫn quét NFC trên thiết bị của bạn'],
        subtype: 'link'
    },
    {id: 3, title: 'Xác thực khuôn mặt', subtitle: ['Giữ điện thoại cố định và đưa khuôn mặt của bạn vào khung hình']},
    {id: 4, title: 'Kiểm tra lại thông tin và bấm “Xác thực tài khoản”'},
];

const EkycContent = ({setStep, isLinkedBank, step}) => {
    return (
        <div className="w-full max-w-4xl py-4 px-2 md:px-4 mx-auto">
            <FeatureRichList data={data} step={step} setStep={setStep} isLinkedBank={isLinkedBank}/>
        </div>
    );
};

export default EkycContent;
