import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {EffectCoverflow, Navigation} from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import './style.css'
import SwipeAnimation from "./SwipeAnimation";

const tabData = (index) => ([
  {
    src: `/img/guild-step/tab${index}-step1.png`,
    alt: `tab${index}-step${index}`
  },
  {
    src: `/img/guild-step/tab${index}-step2.png`,
    alt: `tab${index}-step${index+1}`
  },
  {
    src: `/img/guild-step/tab${index}-step3.png`,
    alt: `tab${index}-step${index+2}`
  },
  {
    src: `/img/guild-step/tab${index}-step4.png`,
    alt: `tab${index}-step${index+3}`
  },
  {
    src: `/img/guild-step/tab${index}-step5.png`,
    alt: `tab${index}-step${index + 4}`
  }
])

const PhoneSwiper = ({activeTab, step, tabsData, setStep}) => {
  const [checkInteraction, setCheckInteraction] = useState(false);
  const [data, setData] = useState(null);
  const swiperRef = useRef(null);
  const dataTabSteps = useMemo(()=>{
    if (tabsData) return tabsData
    return tabData
  },[tabsData]);
  useEffect(() => {
    setData(dataTabSteps(activeTab+1));
    swiperRef.current.swiper.update();
    swiperRef.current.swiper.slideTo(0);
  }, [dataTabSteps, activeTab]);
  
  useEffect(() => {
    if(step > 0 && !window.phoneInteraction) {
      window.phoneInteraction = true
      setCheckInteraction(true)
    }
    if(step !== swiperRef.current.swiper?.activeIndex) {
      swiperRef.current.swiper.slideTo(step);
    }
  }, [step]);
  
  console.log(window.phoneInteraction)

  return (
    <div className="relative flex items-center justify-center w-[255px] h-[526px] bg-cover bg-center">
      <img className='absolute' src="/img/phone.png" alt="phone" />
      <img className='absolute z-[2] top-3' src="/img/phone-island.png" alt="phone-island" />
      <div className="w-[242px] h-[516px] overflow-hidden rounded-[32px]">
        {
          (!checkInteraction || !window.phoneInteraction) && (
            <div className='md:hidden absolute bg-[#00000010] z-10 top-10 image-opacity'>
              <SwipeAnimation/>
            </div>
          )
        }
        
        <Swiper
          ref={swiperRef}
          className="w-full h-full"
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={1}
          active
          pagination={true}
          modules={[EffectCoverflow, Navigation]}
          navigation={{
            prevEl: '.button-prev',
            nextEl: '.button-next',
          }}
          onSlideChangeTransitionEnd={swp => setStep(swp?.activeIndex || 0)}
        >
          {(data || []).map((image, index) => (
            <SwiperSlide key={index}>
              <img
                src={image.src}
                alt={image.alt}
                className="w-full h-full object-cover"
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <button
          className="hidden md:block button-prev navigate-button disabled:opacity-30"
          onClick={() => setStep(step - 1)}
        >
          <img src="/img/left-circle.png" alt="left"/>
        </button>
        
        <button
          className="hidden md:block button-next navigate-button disabled:opacity-30"
          onClick={() => setStep(step + 1)}
        >
          <img src="/img/right-circle.png" alt="right"/>
        </button>
      </div>
    </div>
  );
};

export default PhoneSwiper;
