import React from 'react';
import {STORE} from "../../constants";
import NewLink from "../NewLink/NewLink";

const AppDownload = () => {
	return (
		<div className='relative w-[370px]'>
			<div className='flex flex-col justify-center items-center gap-4'>
				<p className='font-bold'>Tải ứng dụng GooPay</p>
				<div className='text-sm text-center'>Tải ngay ứng dụng GooPay để trải nghiệm và tận hưởng ưu đãi từ những
					dịch vụ vận tải & vận chuyển hành
					khách
				</div>
				<div className='relative'>
					<img src="/img/frame-qr.png" alt="qrcode-popup"/>
					<img className='absolute top-1 left-1 w-[188px] h-[188px]' src="/img/download-qrcode.png" alt="qrcode-popup"/>
				</div>
				<p className='text-sm'>Dùng điện thoại quét QR Code để tải ứng dụng</p>
				<p className='text-[10px]'>hoặc tải tại Apple Store và Google Play</p>
				<div className="flex">
					<NewLink to={STORE.APPS_STORE}>
						<img className='h-[50px]' src="/img/download-ios.png" alt=""/>
					</NewLink>
					<NewLink to={STORE.PLAY_STORE}>
						<img className='h-[50px]' src="/img/download-android.png" alt=""/>
					</NewLink>
				</div>
			</div>
		</div>
	);
};

export default AppDownload;
