import React from 'react';
import './style.css';

const FloatingItem = () => {
	return (
		<div
			className="hidden md:block fixed w-[200px] h-[250px] bg-gradient bottom-4 right-4 rounded-2xl z-10 py-5 text-opacity-90 border-2 shadow-md">
			<div className="relative">
				<p className="text-center text-base font-bold text-white mb-4">Tải ứng dụng ngay</p>
				<p className="text-center text-base font-bold text-white">Ví GooPay</p>
				<div className="relative">
					<img
						className="cursor-pointer mx-auto"
						src="/img/qr-frame.png"
						alt="qr-frame"
					/>
					<img
						className="absolute rounded-2xl top-3 left-[38px] cursor-pointer w-[120px] h-[120px]"
						src="/img/download-qrcode.png"
						alt="qrcode"
					/>
				</div>
			</div>
		</div>
	);
};

export default FloatingItem;
